<template>
  <!--Header Banner-->
  <section class="header-banner">
    <img src="@/assets/images/slider.jpg" />
    <!-- eslint-disable max-len -->
    <button
      class="btn btn-light"
      @click="logout"
      style="top: 5px; right: 5px; position: absolute"
    >
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 16 16"
        class="bi bi-box-arrow-right"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"
        />
        <path
          fill-rule="evenodd"
          d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"
        />
      </svg>
      Çıkış
    </button>
    <a
      href="https://livecast-tr.zoom.us/j/87544188473"
      target="_blank"
      class="btn btn-success btn-lg btn-md mt-2"
    >
      Dönem Toplantısı Girişi</a
    >
  </section>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  methods: {
    ...mapActions({
      logoutAction: 'people/logout',
    }),
    logout() {
      this.logoutAction();
    },
  },
};
</script>

<style lang="scss">
.header-banner {
  position: relative;
  a {
    position: absolute;
    bottom: 40px;
    left: 40px;
    z-index: 1000;
    width: 360px;
  }
}
</style>
